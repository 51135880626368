import React from "react"
import Link from "../IntlLink"
import PubImage from "../PubImage"
import Series from "../Series"
import { getMetadata, formatFrontmatterDate, getAuthors, FormattedAuthor } from "../../util"
import kebabCase from "lodash/kebabCase"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

const cx = classNames.bind(styles);

export function PublicationDetails({ data, collection, showNo, lang }) {

	const sortedAuthors = data.frontmatter.authors && getAuthors(data.frontmatter.authors, data.authors, lang)
	const sortedDiscussants = data.frontmatter.discussants && getAuthors(data.frontmatter.discussants, data.discussants, lang)

	const metadata = getMetadata(collection)

	return(
		<div className={cx("publicationDetails")}>
			{data.frontmatter.series &&
				<Link to={`${metadata.url}${kebabCase(data.frontmatter.series)}/`}>
					<div className={cx("series")}>
						<Series series={data.frontmatter.series} />
					</div>
				</Link>
			}
			{data.fields.collection === "dp" && !showNo &&
				<div className={cx("series", "dpNo")}>
					{data.frontmatter.id}
				</div>
			}
			<Link to={data.fields.originalPath}>
				<div className={cx("title")}>
					{data.frontmatter.title}
				</div>
			</Link>
			<div className={cx("pubDate")}>{formatFrontmatterDate(data.frontmatter, lang)}</div>
			{data.frontmatter.location &&
				<div className={cx("location")}>{data.frontmatter.location}</div>
			}
			{(!data.frontmatter.hideAuthors && data.fields.collection !== "conferences" && sortedAuthors) &&
				<div className={cx("authors")}>
					{sortedAuthors && sortedAuthors.map((author, i) =>
						<div className={cx("author")} key={`author${i}`}>
							<FormattedAuthor author={author} />
						</div>
					)}
					{sortedDiscussants && sortedDiscussants.map((author, i) =>
						<div className={cx("author")} key={`author${i}`}>
							<FormattedAuthor author={author} suffix={` (${lang === "th" ? "ผู้วิจารณ์" : "discussant"})`}/>
						</div>
					)}
				</div>
			}
		</div>
	)

}

export default function Publication({ data, collection, lang }) {

	return(
		<div className={cx("publication", {dp: collection === "dp"})}>
			<div className={cx("pic", {picHideSmall: !data.frontmatter?.cover})}>
				<Link to={data.fields.originalPath}>
				{
					collection === "dp"
					? <div className={cx("dpIcon", "title")}>{data.frontmatter.id}</div>
					:	<div className={cx("zoom-container")}>
							<PubImage f={data.frontmatter} collection={data.fields.collection} />
						</div>
				}
				</Link>
			</div>
			<PublicationDetails data={data} collection={collection} lang={lang} />
		</div>
	)

}