import React from "react"
import Icon from "../Icon"
import { getSeriesName } from "../../util"

export default function Series({ series }) {
  return(
    <>
      <span className="inline-svg" style={{marginRight: "0.25em"}}>
        <Icon icon="series" />
      </span>
      {getSeriesName(series)}
    </>
  )
}