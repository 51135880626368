import React from "react"
import PubBox from "../PubBox"
import classNames from "classnames/bind"
import styles from "./styles.module.scss"
import { getRelatedArticles } from "../../util"
import { sampleSize } from "lodash"

export default function OtherAbridged({ data, relatedByTags, relatedByTopics, title, lang, ...rest }) {

  const cx = classNames.bind(styles);

  const [dataToUse, setDataToUse] = React.useState(null);

  React.useEffect(() => {
    if (data) {
      setDataToUse(data)
    }
    else {
      setDataToUse(getRelatedArticles(relatedByTags, relatedByTopics))
    }
  }, [data, relatedByTags, relatedByTopics])
  

  // return null

  if (!dataToUse || dataToUse.length === 0)
    return null

  // console.log(dataToUse)

  return(
    <div className={cx("trendingContainer", "noprint")} {...rest}>
      <h5>{title}</h5>
      <div className={cx("grid")}>
      {
        sampleSize(dataToUse, 3).map((node, i) =>
          <PubBox
            key={`node${i}`}
            lang={lang}
            node={node.actualNode ? node.actualNode[0] : node}
            showCaption={false}
          />
        )
      }
      </div>
    </div>
  )

}