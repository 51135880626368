import React from "react"
import PubImage from "../PubImage"
import Link, { navigate } from "../IntlLink"
import ReactWordcloud from 'react-wordcloud'
import classNames from "classnames/bind"
import styles from "./styles.module.scss"
import { PublicationDetails } from "../Publication"
import OtherAbridged from "../OtherAbridged"
import { useStaticQuery, graphql } from "gatsby"

export default function FeaturedInList({ node, tagCounts, pre, text, lang, floatDetails=true }) {

  const cx = classNames.bind(styles);

  // * INTENTIONALLY SKIP THIS FOR NOW
  const SKIP_GA = true

  const trendingData = null

  // const trendingData = SKIP_GA && useStaticQuery(graphql`
  //   query {
  //     allPageViews {
  //       nodes {
  //         actualNode {
  //           fields {
  //             originalPath
  //             collection
  //           }
  //           frontmatter {
  //             title
  //             cover {
  //               childImageSharp {
  //                 gatsbyImageData(layout: CONSTRAINED, width: 400, height: 250)
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
    
  // `)

  function handleResize() {
    if (window.document.getElementById('cloud'))
      window.document.getElementById('cloud').setAttribute('height', '100%')
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return(
    <React.Fragment>
      <div className={cx("topGrid", {full: !tagCounts && !text})}>
        <div className={cx("featured")}>
          <Link to={node.fields.originalPath}>
            <PubImage f={node.frontmatter} collection={node.fields.collection} customStyle={"fullwidth"} />
          </Link>
          <div className={cx("featuredDetails", {floatDetails: floatDetails})}>
            {pre && <div className={cx("latest")}>{pre}</div>}
            <PublicationDetails data={node} collection={node.fields.collection} lang={lang} />
          </div>
        </div>
        {tagCounts &&
          <div className={cx("side")}>
            <div className={cx("tags")}>
              <div className={cx("title")}>Tag Cloud</div>
              <div>
                <ReactWordcloud
                  words={tagCounts.group.map(x => ({text: x.fieldValue, value: x.totalCount}))}
                  maxWords={30}
                  callbacks={{
                    onWordClick: (tag) => navigate(`/search/?collection=${node.fields.collection}&tags=${tag.text}`)
                  }}
                  options={{
                    rotations: 2,
                    rotationAngles: [-90, 0],
                    fontFamily: "Dindan",
                    enableTooltip: false,
                    deterministic: true,
                    svgAttributes: {
                      id: 'cloud',
                    },
                    fontSizes: [6, 22],
                  }}
                />
              </div>
            </div>
          </div>
        }
        {!tagCounts && text &&
          <div className={cx("side")}>
            <div className={cx('xx')}>
              <h4>{text.title}</h4>
              <p>{text.desc}</p>
            </div>
          </div>
        }
      </div>
      {tagCounts && trendingData && trendingData.allPageViews.nodes.length > 0 &&
        <div className={cx("side")}>
          <OtherAbridged
            data={trendingData.allPageViews.nodes}
            title={"Trending aBRIDGEd"}
          />
        </div>
      }
      <hr className={cx("hr")} />
    </React.Fragment>
  )
}