import React from "react"
import Layout from "../../components/Layout"
import FeaturedInList from "../../components/FeaturedInList"
import Publication from "../../components/Publication"
import { graphql } from "gatsby"
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { navigate } from "../../components/IntlLink"
import { seriesPlaceholder } from "../../util"


export default function PageTemplate({ pageContext: { language, availableLanguages, collection, series, humanPageNumber, numberOfPages, pathPrefix, intl }, data: { tagCounts, allPub } }) {

	const notsmall = useMediaQuery('(min-width:550px)');

	function MyPagination() {
		if (numberOfPages === 1) return null
		return(
			<Pagination
				count={numberOfPages}
				page={humanPageNumber}
				siblingCount={notsmall ? 2 : 1}
				size={notsmall ? "medium" : "small"}
				onChange={(event, page) => page === 1 ? navigate(pathPrefix + "/") : navigate(`${pathPrefix}/page/${page}/`)}
			/>
		)
	}

	const specialPage = humanPageNumber === 1 && ["dp", "pierspectives", "abridged", "conferences", "blog"].includes(collection)

	const pre = {
		// research
		dp: "Latest Discussion Paper",
		pierspectives: "Latest PIERspectives",
		abridged: "Latest aBRIDGEd",
		// events
		conferences: "Featured event",
		workshops: "Latest workshop",
		forums: "Latest policy forum",
		seminars: "Latest seminar",
		exchanges: "Latest exchange",
		// others
		blog: "Latest blogpost",
		announcements: "Latest announcement",
	}

	const text = {
		dp: {title: "PIER Discussion Paper", desc: "ผลงานวิจัยฉบับเต็มโดยนักวิจัยของสถาบันและนักวิชาการภายนอก"},
		abridged: {title: "aBRIDGEd", desc: "บทความวิจัยฉบับย่อ ที่สังเคราะห์องค์ความรู้จากงานวิจัยเชิงลึกด้านเศรษฐศาสตร์ในรูปแบบที่เข้าถึงง่าย เพื่อเชื่อมต่องานวิจัยกับกลุ่มผู้อ่านที่หลากหลาย"},
		workshops: {title: "Research Workshops", desc: "การประชุมเชิงปฏิบัติการ เพื่อการแลกเปลี่ยนองค์ความรู้ภายในเครือข่ายนักวิจัย"},
		forums: {title: "Policy Forums", desc: "การประชุมเชิงนโยบาย เพื่อพูดคุยในประเด็นทางนโยบายที่เฉพาะเจาะจงกับผู้ที่มีส่วนเกี่ยวข้อง"},
		seminars: {title: "Economic Seminars", desc: "งานสัมมนาวิชาการ บรรยายโดยผู้ทรงคุณวุฒิในสาขาต่างๆ พร้อมทั้งเปิดโอกาสให้ซักถามแลกเปลี่ยนความคิดเห็น"}
	}

	return( 
		<Layout
			lang={language}
			availableLanguages={availableLanguages}
			series={series}
			placeholder={seriesPlaceholder[series]}
			isList={true}
			collection={collection}
			originalPath={intl.originalPath + "/"}
			// title={series && `${series} Series`}
			// subtitle={series && `PIER ${startCase(collection)}`}
		>
			{specialPage && allPub.nodes.length > 0 &&
				<FeaturedInList
					node={allPub.nodes[0]}
					tagCounts={collection === "abridged" && tagCounts}
					pre={pre[collection] + (collection === "dp" ? `: No. ${allPub.nodes[0].frontmatter.id}` : "")}
					text={text[collection]}
					lang={language}
					floatDetails={!["conferences", "workshops", "forums"].includes(collection)}
				/>
			}
			<div className="bottom-margin-3" style={{display: "flex", justifyContent: "flex-end"}}>
				<MyPagination/>
			</div>
			<div className="publication-list">
				{
					allPub.nodes.slice(specialPage ? 1 : 0).map((node, i) =>
						<Publication data={node} collection={collection} key={`publication${i}`} lang={language} />
					)
				}
			</div>
			<div className="top-margin-3" style={{display: "flex", justifyContent: "flex-end"}}>
				<MyPagination />
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query ($collection: String, $series: String, $language: String, $skip: Int!, $limit: Int!) {
		tagCounts: allMdx(
			filter: {fields: {collection: {eq: $collection}, lang: {in: [$language, null]}}, frontmatter: {series: {eq: $series}, hidden: {ne: true}}},
		) {
			group(field: frontmatter___tags) {
				fieldValue
				totalCount
			}
		}
		allPub: allMdx(
			filter: {fields: {collection: {eq: $collection}, lang: {in: [$language, null]}}, frontmatter: {series: {eq: $series}, hidden: {ne: true}}},
			sort: {fields: [frontmatter___date, frontmatter___id], order: [DESC, DESC]}
      skip: $skip
      limit: $limit
		) {
			nodes {
        fields {
          collection
					originalPath
				}
				frontmatter {
					id
					title
					series
					date
					endDate
					location
					subtitle
					authors
					hideAuthors
          discussants
					cover {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 500)
							fw: gatsbyImageData(layout: FULL_WIDTH)
						}
					}
					topics
					tags
				}
				authors {
					frontmatter {
						memberid
						title
						exclude
					}
					fields {
						originalPath
						lang
					}
				}
        discussants {
					frontmatter {
						memberid
						title
						exclude
					}
					fields {
						originalPath
						lang
					}
				}
			}
		}
	}
`